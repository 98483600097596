.App {
  text-align: center;
  /* display: flex; */
  /* display: grid; */
  grid-template-columns: 20vw auto;
  background-color: white;;
  color: white;
  position: absolute;
  top: 0; bottom: 0;right: 0;left: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.sidemenu {
  width:40%;
  height: 100%;
  padding: 10px;
  position: fixed;
  float: left;
  background-color: #232323;
  color: #D2D2D2;
  text-align: left;
  font-size: large;
  /* grid-column-start: 1; */
}

.generated-text {
  margin-top: 40px;
  padding: 10px;
  height: 100vh;
  overflow-y: scroll;
}

.side-menu-button {
  padding: 12px;
  border: 1px solid #D2D2D2;
  border-radius: 5px;
  text-align: left;
  transition: ease 0.25s all;
}

.side-menu-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.side-menu-button-span {
  padding-left: 6px;
  padding-right: 12px;
}

.filtersbox {
  flex: 1;
  /* grid-column-start: 3; */
  /* margin-left: 10%;
  margin-right: 20%; */
  margin-left: 40%;
}

.title {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 43px;
  text-align: center;
  color: #4B4B4B;
  padding-top: 50px;
  padding-bottom: 20px;
}

.filters {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.sliders {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.slider-card {
  display: flex;
  flex-direction: column;
  width: 200px;
  padding: 10px;
  background-color: #F5F5F5;
  border-radius: 5px;
}

.slider-card-title {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #4B4B4B;
}

.slider-count {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 10px;
  line-height: 12px;
  color: #999999;
  display: flex;
  justify-content: space-evenly;
}

.generate-button {
  transition: .25s;
  display: flex;
  flex-direction: column;
  max-width: 630px;
  width: 90%;
  padding: 5px;
  border: 1px solid #3369CC;
  border-radius: 5px;
  margin-bottom: 50px;
}

.generate-button:hover {
  transition: .25s;
  background-color: #3369CC;
  font-style: white;
}

.generate-button:hover .generate-button-text{
  color: white;
}

.accordion {
  max-width: 630px;
  width: 90%;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 12px;
  line-height: 15px;
}

.accordion-color {
  background-color: black;
}

.accordion-title {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #4B4B4B;
}

.accordion-subtitle {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 12px;
  color: #999999;
  padding-left: 10px;
}

.accordion-details {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
}

.generate-button-text {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 12px;
  line-height: 15px;
  color: #3369CC;
}

.loading-symbol {
  color: white;
}

.custom-focus-input-holder {
  width: 100%;
  padding-bottom: 5px;
}

.custom-focus-input {
  width: 100%;
  border-radius: 5px;
  border: none;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  color: black;
  padding: 5px;
  padding-left: 10px;
  font-size: 1.25em;
  outline: none;
}

.input-icon {
  position: absolute;
  right: 20px;
  top: 90.5px;
  color: #3369CC;
}

.current-items-box {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 5px;
  width: 100%;
  min-height: 60px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #999999;
  /* box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25); */
}

.past-items-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #999999;
  /* box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25); */
}

.selected-item-box {
  display: flex;
  align-items: center;
  flex-direction: row;
  border: 1px solid #999999;
  height: 30px;
  border-radius: 7px;
}

.selected-item-text {
  font-family: 'Montserrat', sans-serif;  
  font-style: normal;
  font-weight: 500;
  font-size: 1.25em;
  line-height: 12px;
  color: #646464;
  margin: 5px;
  
}

.blinking {
  width: 7px;
  height: 15px;
  background-color: white;
  -webkit-animation: blinking 1s infinite;
  animation: blinking 1s infinite;
  display: inline-block;
  vertical-align: middle;
}

@keyframes blinking {
  0% {
      background-color: transparent;
  }
  49% {
      background-color: transparent
  }
  50% {
      background-color: white;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
